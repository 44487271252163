<template>
  <div>
    <div class="events-mapping-wrapper">
      <MappingTable
        :mapping-type="EVENTS_MAPPING"
        @open-popup="editMapping"
        :top-header-position="topHeaderPosition"
      />
      <SuggestedMappingPopup
        v-if="selectedFeed !== 'huddle' && editData"
        :mapping-type="EVENTS_MAPPING"
        :edit-data="editData"
        @close-popup="closeExternalPopup"
      />
      <MappingPopup
        v-else-if="selectedFeed === 'huddle' && editData"
        v-model:screen="screen"
        :screen-configs="screens"
        :edit-data="editData"
        :mapping-type="EVENTS_MAPPING"
        :selected-feed="selectedFeed"
        :newly-mapped="newlyMapped"
        :loading="popupLoading"
        :selected-sport-label="selectedSportLabel"
        @close="closePopup"
      >
        <template #default="{ view, popupConfig }">
          <component
            :is="view"
            :edit-data="editData"
            :selected-feed="selectedFeed"
            :newly-mapped="newlyMapped"
            :mapping-suggestions="mappingSuggestions"
            :mapping-details="mappingDetails"
            :selected-squad="selectedSquad"
            :selected-player="selectedPlayer"
            :popup-config="popupConfig"
            :selected-sport-label="selectedSportLabel"
            :mapping-type="EVENTS_MAPPING"
            @select-squad="selectSquad"
            @select-player="selectPlayer"
            @change-screen="changeScreen"
            @load-mapping-details="reloadMappingDetails"
            @approve-mapping-details="approveMapping"
            @unmap-mapping-details="unmapMappingDetails"
            @approve-player-mapping="approvePlayerMapping"
            @close-popup="closePopup"
          />
        </template>
      </MappingPopup>
    </div>
    <div
      class="no-mapping-events"
      v-if="!events?.length"
    >
      No data were found for selected parameters
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { find, reduce } from 'lodash';
import { mappingTypes, getPlayerMappingFieldName } from '@/services/helpers/mappings-mapper';
import { getInternalEventMappingDetails, getExternalEventMappingDetails } from '@/services/api';
import MappingTable from '@/components/mapping/common/MappingTable';
import MappingPopup from '@/components/mapping/common/MappingPopup';
import SuggestedMappingPopup from '@/components/mapping/common/SuggestedMappingPopup';
import { screenTypes, screens } from './config';

const { EVENTS_MAPPING } = mappingTypes;

export default {
  components: {
    MappingTable,
    MappingPopup,
    SuggestedMappingPopup,
  },
  props: {
    topHeaderPosition: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  setup() {
    const store = useStore();

    const selectedSportLabel = computed(() => store.getters.mappingSelectedSportLabel);
    const selectedFeed = computed(() => store.getters.mappingSelectedFeed);

    const fetchMappingEvents = (reload = false) => store.dispatch(`${reload ? 're' : ''}loadMappingData`, EVENTS_MAPPING);

    const events = computed(() => store.getters.mappingEntries);
    const mappingLoading = computed(() => store.getters.mappingLoading);

    const editingMapping = ref(false);
    const editData = ref(null);
    const screen = ref(screenTypes.EVENT_SUGGESTIONS);
    const popupLoading = ref(false);
    const mappingSuggestions = ref(null);
    const mappingDetails = ref(null);
    const selectedSquad = ref(null);
    const selectedPlayer = ref(null);
    const newlyMapped = ref(false);

    const changeScreen = (newScreen) => {
      screen.value = newScreen;
    };
    const selectSquad = (newSquad) => {
      selectedSquad.value = newSquad;
      selectedPlayer.value = null;
      screen.value = screenTypes.SQUAD_DETAILS;
    };
    const selectPlayer = (newPlayer) => {
      selectedPlayer.value = newPlayer;
      screen.value = screenTypes.PLAYER_SUGGESTIONS;
    };

    const loadMappingDetails = ({ row, feed }) => {
      popupLoading.value = true;

      const getEventMappingDetails = selectedFeed.value === 'huddle'
        ? getInternalEventMappingDetails
        : getExternalEventMappingDetails;
      const feedParam = selectedFeed.value === 'huddle'
        ? feed
        : selectedFeed.value;

      return getEventMappingDetails({ id: row.id, feed: feedParam })
        .then((response) => find(response.data?.allEventMappings?.nodes ?? [], { mappingType: 'EXACT_MATCH' }))
        .then((rawMappingDetails) => {
          const {
            sportsLabel,
            competitionMapping,
            competitorExtIds,
            eventCompetitorMappingsByEventMappingId,
            playerTeamEventMappingsByFeedAndEventExtId,
            competitorsFlipped,
            ...eventMapping
          } = rawMappingDetails ?? {};
          const { regionMapping } = competitionMapping ?? {};
          const playerMappingFieldKey = getPlayerMappingFieldName(sportsLabel);
          const homeIndex = competitorsFlipped ? 1 : 0;
          const awayIndex = competitorsFlipped ? 0 : 1;
          const awayCompetitorMapping = find(
            eventCompetitorMappingsByEventMappingId.nodes,
            { competitorMapping: { extId: competitorExtIds[awayIndex] } },
          )?.competitorMapping;
          const homeCompetitorMapping = find(
            eventCompetitorMappingsByEventMappingId.nodes,
            { competitorMapping: { extId: competitorExtIds[homeIndex] } },
          )?.competitorMapping;
          const [awaySquadMapping, homeSquadMapping] = reduce(
            playerTeamEventMappingsByFeedAndEventExtId.nodes,
            (partitionedSquads, player) => {
              const playerMapping = player[playerMappingFieldKey];

              if (player.teamExtId === competitorExtIds[homeIndex]) {
                return [
                  [...partitionedSquads[0]],
                  [...partitionedSquads[1], playerMapping],
                ];
              }

              return [
                [...partitionedSquads[0], playerMapping],
                [...partitionedSquads[1]],
              ];
            },
            [[], []],
          );
          return {
            event: eventMapping,
            region: regionMapping,
            competition: competitionMapping,
            awayCompetitor: awayCompetitorMapping,
            homeCompetitor: homeCompetitorMapping,
            awaySquad: awaySquadMapping,
            homeSquad: homeSquadMapping,
            competitorsFlipped,
          };
        })
        .then((details) => {
          mappingDetails.value = details;

          if (!selectedSquad.value) return;

          if (selectedSquad.value.competitor.extId === details.awayCompetitor.extId) {
            selectedSquad.value = {
              competitor: details.awayCompetitor,
              players: details.awaySquad,
            };
          } else {
            selectedSquad.value = {
              competitor: details.homeCompetitor,
              players: details.homeSquad,
            };
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          popupLoading.value = false;
        });
    };

    const editMapping = ({ row, feed }) => {
      const exactMappingFound = row.mappingType
        ? row.mappingType === 'EXACT_MATCH'
        : find(row.mappings, { mappingType: 'EXACT_MATCH', feed });

      if (exactMappingFound) {
        screen.value = screenTypes.EVENT_DETAILS;
        loadMappingDetails({ row, feed });
      } else {
        screen.value = screenTypes.EVENT_SUGGESTIONS;
      }

      editData.value = { feed, row };
    };

    const reloadMappingDetails = () => {
      screen.value = screenTypes.EVENT_DETAILS;
      editData.value.mappingType = 'EXACT_MATCH';
      fetchMappingEvents(true);
      return loadMappingDetails(editData.value);
    };

    const closePopup = () => {
      editData.value = null;
      selectedSquad.value = null;
      selectedPlayer.value = null;
      mappingSuggestions.value = null;
      mappingDetails.value = null;
      popupLoading.value = false;
      newlyMapped.value = false;
    };
    const closeExternalPopup = (message) => {
      store.dispatch('saveEventSuggestedMappings', []);
      closePopup();
      if (message === 'reload') fetchMappingEvents();
    };

    const approveMapping = () => {
      popupLoading.value = true;
      reloadMappingDetails()
        .then(() => {
          newlyMapped.value = true;
          screen.value = 'EVENT_DETAILS';
        })
        .finally(() => {
          popupLoading.value = false;
        });
    };
    const approvePlayerMapping = () => {
      popupLoading.value = true;
      reloadMappingDetails()
        .then(() => {
          newlyMapped.value = true;
          screen.value = 'SQUAD_DETAILS';
        })
        .finally(() => {
          popupLoading.value = false;
        });
    };
    const unmapMappingDetails = () => {
      popupLoading.value = true;
      fetchMappingEvents(true)
        .then(() => {
          screen.value = 'EVENT_SUGGESTIONS';
          mappingDetails.value = null;
          selectedSquad.value = null;
          selectedPlayer.value = null;
          newlyMapped.value = false;
          editData.value.row.mappedEntity = null;
          editData.value.row.mappingId = null;
          editData.value.row.mappingType = null;
          editData.value.row.mappings = [];
        })
        .finally(() => {
          popupLoading.value = false;
        });
    };

    return {
      selectedSportLabel,
      selectedFeed,
      editingMapping,
      editData,
      selectedSquad,
      selectedPlayer,
      editMapping,
      events,
      closePopup,
      closeExternalPopup,
      mappingLoading,
      EVENTS_MAPPING,
      fetchMappingEvents,
      selectSquad,
      selectPlayer,
      screen,
      changeScreen,
      screens,
      mappingSuggestions,
      mappingDetails,
      popupLoading,
      reloadMappingDetails,
      approveMapping,
      unmapMappingDetails,
      newlyMapped,
      approvePlayerMapping,
    };
  },
};
</script>

<style lang="scss" scoped>
.no-mapping-events {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 260px;
  width: 100%;
  font-size: 14px;
  color: #A9A9A9;
}
</style>
